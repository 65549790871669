import imagen1 from "../datos/Imagen1.jfif";
import imagen2 from "../datos/Imagen2.jpg";
import imagen3 from "../datos/Imagen3.jfif";
import imagen4 from "../datos/Imagen4.jfif";
import imagen5 from "../datos/Imagen5.jfif";
import imagen6 from "../datos/Imagen6.jfif";
import imagen7 from "../datos/Imagen7.jfif";
import imagen8 from "../datos/Imagen8.jfif";
import imagen9 from "../datos/Imagen9.jfif";
import imagen10 from "../datos/Imagen10.jfif";

export default [
  imagen1,
  imagen2,
  imagen3,
  imagen4,
  imagen5,
  imagen6,
  imagen7,
  imagen8,
  imagen9,
  imagen10,
];
